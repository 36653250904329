import React from 'react';
import { RouterProvider } from 'react-router-dom';

import I18nProvider from './modules/i18n';
import router from './router';
import StoreProvider from './store/Provider';
import ActiveJourneyProvider from './modules/journeys/ActiveJourneyProvider/ActiveJourneyProvider';
import UploadFeedbackModal from './modules/upload/modal/uploadFeedbackModal';
import GlobalDataLoader from './modules/globalDataLoader/GlobalDataLoader';

const App = () => (
  <StoreProvider>
    <I18nProvider>
      <GlobalDataLoader />
      <ActiveJourneyProvider>
        <RouterProvider router={router} />
        <UploadFeedbackModal />
      </ActiveJourneyProvider>
    </I18nProvider>
  </StoreProvider>
);

export default App;
